import React from 'react';
import { Container, Content } from '../../components/Home/homeStyledComponents';
import MenuBar from '../../components/Home/MenuBar';
import Footer from '../../components/Home/Footer';
import InsuranceTillisteBlock from '../../components/Insurance/InsuranceTillisteBlock';
import Layout from '../../layouts/index';

class InsuranceTilliste extends React.Component {
  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Layout routeSlug="InsuranceTilliste">
        <Container>
          <Content>
            <MenuBar />
            <InsuranceTillisteBlock />
            <Footer />
          </Content>
        </Container>
      </Layout>
    );
  }
}

export default InsuranceTilliste;
